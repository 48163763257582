import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Masonry from "../components/masonry"

const IndexPage = ({ data }) => {
  const homePhotos = data.sanityGalleries.pictures

  return (
    <Layout>
      <SEO title="Home" />
      <h1 className="isVisuallyHidden">Home</h1>
      <Masonry photos={homePhotos} />
    </Layout>
  )
}

export default IndexPage

export const homePhotos = graphql`
  query homePhotos {
    sanityGalleries(title: {eq: "Home"}) {
      id
      pictures {
        _key
        asset {
          fluid(maxWidth: 750) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }
  }
`
